@import url(https://fonts.googleapis.com/css?family=Poppins:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i&display=swap);
@import url(https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  outline: 0;
  text-decoration: none !important;
}

body {
  font-family: "Poppins", "fontAwesome", sans-serif;
  font-size: 14px;
  line-height: 1.5;
}

a,
.btn-link {
  color: #054988;
  cursor: pointer;
}

.btn:not(.btn-link) {
  margin: 0 2px;
}

.nav-item {
  cursor: pointer;
}

::placeholder {
  color: #454b60;
  font-size: 13px;
}

.min-height-100vh {
  min-height: 100vh;
}

.bg-none {
  background: transparent !important;
}

a {
  color: #054988;
}

.font-weight-500 {
  font-weight: 500;
}

.font-weight-600 {
  font-weight: 600;
}

.font-size-9 {
  font-size: 9px;
}

.font-size-10 {
  font-size: 10px;
}

.font-size-11 {
  font-size: 11px;
}

.font-size-12 {
  font-size: 12px;
}

.font-size-13 {
  font-size: 13px;
}

.bg-secondary {
  background: #002f5b !important;
}

.bg-primary {
  background: #054988 !important;
}

.text-primary {
  color: #054988 !important;
}

.btn-primary {
  background: #054887 !important;
  border-color: #054988 !important;
}

.btn-outline-primary {
  color: #054887 !important;
  border-color: #054887 !important;
}

.btn-outline-primary:hover {
  background: #054887 !important;
  color: #fff !important;
}

.text-dark {
  color: #000 !important;
}

.text-success {
  color: #3ec898 !important;
}

.text-danger {
  color: #f26578 !important;
}

/*--------Badge outlines----------*/

.badge {
  border: 1px solid transparent;
}

.badge.badge-default {
  background-color: #747474;
}

.badge.badge-outlined {
  background-color: transparent;
}

.badge.badge-outlined.badge-default {
  border-color: #747474;
  color: #747474;
}

.badge.badge-outlined.badge-primary {
  border-color: #054988;
  color: #054988;
}

.badge.badge-outlined.badge-secondary {
  border-color: #323a45;
  color: #323a45;
}

.badge.badge-outlined.badge-success {
  border-color: #3ec898;
  color: #3ec898;
}

.badge.badge-outlined.badge-warning {
  border-color: #dec53c;
  color: #dec53c;
}

.badge.badge-outlined.badge-info {
  border-color: #29b6f6;
  color: #29b6f6;
}

.badge.badge-outlined.badge-danger {
  border-color: #f26578;
  color: #f26578;
}

/*--------.Badge outlines----------*/

.dropdown-menu {
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.5);
  border-radius: 3px 3px 0px 0px;
  border-color: #eaeaea;
  position: relative;
  border: 0;
  border-bottom: 4px solid #054988;
}

.dropdown-item.active,
.dropdown-item:active {
  background-color: #054988 !important;
}

.dropdown-item:focus {
  outline: 0;
}

.dropdown-item {
  font-size: 12px;
  font-weight: 600;
  padding: 10px 12px;
  color: #333a4a;
}

.profile-dropdown {
  position: relative;
}

.profile-dropdown .nav-icon {
  width: 14px;
  margin-right: 8px;
}

.profile-dropdown .dropdown-menu {
  position: absolute;
  z-index: 9999999;
  left: 0;
  background: #054988;
  border-radius: 0;
  border: 0;
  top: 46px;
  margin: 0;
}

.profile-dropdown .dropdown-divider {
  border-top: 1px solid #063969;
  margin: 0;
}

.profile-dropdown:hover > .dropdown-menu {
  display: block;
}

.profile-dropdown .dropdown-item {
  color: #fff;
  display: flex;
  align-items: center;
}

.profile-dropdown .dropdown-item:hover,
.profile-dropdown .dropdown-item:active,
.profile-dropdown .dropdown-item:focus {
  background: #053e73;
  color: #fff;
}

.profile-dropdown > .dropdown-toggle:active {
  pointer-events: none;
}

.profile-dropdown .nav-link {
  font-size: 12px;
  color: #fff !important;
  font-weight: 600;
  padding-left: 30px;
}

.profile-dropdown .nav-link:hover,
.profile-dropdown .nav-link:focus,
.profile-dropdown .nav-link:active {
  background: #053e73;
  color: #fff;
}

.modal-content {
  border: 0;
  border-bottom: 4px solid #054988;
  border-radius: 0;
  padding: 0;
  margin: 0;
}

.form-control {
  height: 45px;
  font-size: 13px;
}

.form-control:focus {
  outline: 0;
  box-shadow: none;
}

.form-control-file {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-control {
  height: 35px !important;
}

.form-control-sm {
  height: 32px !important;
  font-size: 12px;
  line-height: normal;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #054988;
  background-color: #054988;
}

.custom-switch .custom-control-label::after {
  top: calc(0.25rem + 1px);
}

.custom-switch .custom-control-label::before {
  top: 3px;
}

.custom-switch-right {
  padding-right: 3rem;
  padding-left: 0;
}

.custom-switch-right .custom-control-input {
  right: 0;
  left: auto;
}

.custom-switch-right .custom-control-label {
  display: block;
}

.custom-switch-right .custom-control-label::before {
  right: -2.25rem;
  left: auto;
}

.custom-switch-right .custom-control-label::after {
  right: calc(-2.25rem + 14px);
  left: auto;
}

.custom-switch-right .custom-control-label::after {
  right: calc(-2.25rem + 14px);
  left: auto;
}

/* ---Login section--- */

.login-bg-image {
  background: url(/static/media/loginBackground-image.a859f950.png) no-repeat center;
  background-size: cover;
}

.login-card.card {
  border: 2px solid #00396f;
  background: #023260 !important;
  max-width: 395px;
  margin: 0 auto;
}

.bookmark-icon {
  right: 30px;
  top: 0;
  background: #2d5577;
  padding: 10px 3px 3px 3px;
  border-radius: 0 0 10px 10px;
}

.white-links-separator .nav-item:not(:last-child) {
  position: relative;
}

.white-links-separator .nav-item:not(:last-child):after {
  content: "";
  position: absolute;
  top: 0.6rem;
  bottom: 0.6rem;
  right: 0;
  border-right: 1px solid white;
}

/* ---.Login section--- */

/*---- app header----*/

.app-header {
  z-index: 99999;
  height: 62px;
}

.notification-badge {
  position: absolute;
  padding: 3px;
  right: -4px;
  display: inline-block !important;
  top: -1px;
}

/*---- .app header----*/

/*------sidebar--------*/

.left-navbar__width {
  width: 210px;
}

.content-block__width {
  overflow-y: auto;
  background: #eff5fa;
  height: 100vh;
}

.open-sidebar {
  position: absolute;
  top: 58px;
  left: 0;
  background-image: linear-gradient(#004f94, #002e59);
  z-index: 99999999;
  width: 180px;
  height: calc(100vh - 58px) !important;
  z-index: 9;
  transition: all 0.3s linear;
  transition-delay: 0s;
}

.open-sidebar.collapse:not(.show) {
  display: block;
  transform: translate(-180px, 0);
}

.open-sidebar.show {
  transform: translate(0);
}

.sidebar-menu {
  width: 56px;
  position: absolute;
  background: #eaeef2;
  z-index: 9;
}

.sidebar-nav {
  height: calc(100vh - 58px);
  overflow-y: auto;
}

.sidebar-nav .nav-link {
  font-size: 12px;
  color: #fff;
  padding: 0.8rem 1rem;
  font-weight: 600;
}

.sidebar-nav .nav-link:hover,
.sidebar-nav .nav-link.active {
  color: #fff;
  background: rgba(0, 0, 0, 0.07);
}

.sidebar-nav .nav-icon {
  width: 14px;
  margin-right: 8px;
}

.sidebar-menu:hover .open-sidebar {
  display: block;
  transform: translate(0);
}

.sidebar-menu.configuration-view button {
  height: 31px;
  padding: 2px 17px !important;
}

.sidebar-menu.configuration-view {
  top: 1px;
}

.sidebar-menu.configuration-view .open-sidebar {
  top: 31px;
  height: calc(100vh - 32px) !important;
}

.sidebar-active {
  min-width: 180px;
}

/*------.sidebar--------*/

/*----Pages layout-------*/

.page-details-stripe {
  background: #eaeef2;
  height: 58px;
  padding-left: 56px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.2s linear;
  transition-delay: 0s;
}

.page-details-substripe {
  background: #eaeef2;
  height: 48px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: all 0.2s linear;
  transition-delay: 0s;
  border: 1px solid gainsboro;
}

.content-wrapper .global-form {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 10px;
}

.content-wrapper .global-form .form-check {
  margin: 0 0 7px 15px;
}
.content-wrapper .global-form label {
  margin-bottom: 0;
  margin-top: 5px;
}

.stripe-heading {
  color: #000000;
  font-size: 16px;
}

/*----.Pages layout-------*/

/*-----Dashboard-------*/

.border-top-width-4 {
  border-top-width: 4px;
  border-color: #f8f8f8;
}

.border-top-muted {
  border-top-color: #747474;
}

.border-top-success {
  border-top-color: #3ec898;
}

.border-top-warning {
  border-top-color: #dec53c;
}

.border-dashed {
  border-style: dashed;
}

.card-inactive-bg {
  background: #f46979;
  border-color: transparent;
}

.inactive-arrow-icon {
  position: absolute;
  right: 10px;
  top: calc(50% - 8px);
}

.dashboard-status-tab .nav-item {
  margin: 5px 20px 5px 10px;
}

.dashboard-status-tab .nav-link {
  border-radius: 60px;
  font-weight: 500;
  padding: 7px 25px;
}

.dashboard-status-tab .nav-link.active {
  background: #054988;
  color: #fff;
  border-color: transparent;
}

.dashboard-status-child-tab .nav-link {
  color: #000000;
  font-size: 12px;
  border: 0;
  position: relative;
}

.dashboard-status-child-tab .nav-item:not(:last-child) .nav-link:after {
  content: "";
  position: absolute;
  top: 10px;
  bottom: 10px;
  border-right: 1px solid gray;
  right: 0;
}

.dashboard-status-child-tab .nav-link.active {
  font-weight: 600;
  background: transparent;
  border: 0;
  color: #000000;
}

.dashboard-status-child-tab .nav-link:hover {
  color: #000000;
}

.game-remote-size {
  font-size: 30px;
}

.status-card-popover .popover {
  border: 0;
}

.ellipsis-icon-color {
  color: #d0d0d0;
}

.configuration-table td {
  padding: 6px;
  font-size: 12px;
  line-height: 26px;
  color: #333a4a;
  border-color: #eeeeee !important;
}

.configuration-table table tr:nth-child(1) td {
  box-shadow: 2px 1px 4px #c7c7c7;
  font-weight: 500;
  position: relative;
}

.configuration-table table tr:nth-child(even) td {
  background: #fff;
}

.configuration-table table tr:nth-child(odd) td {
  background: #fafafa;
}

.configuration-table table tr td:first-child {
  padding-left: 20px;
}

.configuration-table table tr td:last-child {
  padding-right: 20px;
}

.configuration-table .ht_master,
.configuration-table .wtHolder,
.configuration-table .wtHider,
.configuration-table table {
  width: 100% !important;
}

/*--------Status Navigation filter--------*/

.status-borard-wrapper {
  height: calc(100% - 58px);
  overflow-y: auto;
}

.status-filter-nav {
  background: #eaeef2;
  height: calc(100vh - 62px);
  overflow: auto;
  width: 180px;
  z-index: 0;
}

.status-filter-nav .nav-link {
  color: #333a4a;
  font-weight: 500;
  /* line-height: 24px; */
  /* border-bottom: 1px solid #E3E3E3; */
  /* padding: 10px 16px; */
}

.status-filter-nav .nav-link.active {
  background: #003e76;
  color: #fff;
}

.status-filter-nav .nav-link .badge {
  min-width: 40px;
  padding: 4px 8px;
  text-align: center;
}

.nav-category-head {
  background: #e0e7fa;
  line-height: 27px;
}

/*--------.Status Navigation filter--------*/

.ag-grid-react {
  width: 100%;
  height: 100%;
}

.react-toast-notifications__container {
  z-index: 999999999999 !important;
}

.rag-red {
  background-color: lightcoral;
}

.rag-green {
  background-color: lightgreen;
}

.rag-amber {
  background-color: lightsalmon;
}

.rag-yellow {
  background-color: #dec53c;
}

.rag-dark {
  background-color: #d6d8d9;
}

.select-card-checkbox {
  top: 5px;
  left: 5px;
  z-index: 1;
  display: inline-block;
  width: 0px;
}

.select-status-card:hover {
  box-shadow: 0 0.125rem 0.5rem rgba(0, 0, 0, 0.12) !important;
}

.select-card-checkbox:checked + .select-status-card:before {
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(2, 42, 78, 0.05);
  position: absolute;
  z-index: 0;
}

.select-status-card .status-card-body-mask {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
  top: 0;
  left: 0;
}

.play-pause-stop {
  background: #1fa3d8;
  padding: 3px 5px;
  border-radius: 4px;
}

.play-pause-stop button {
  display: inline-block;
  padding: 0 16px;
  vertical-align: middle;
  background: unset;
  border: unset;
  color: #fff;
}

.play-pause-stop button:disabled {
  color: rgba(255, 255, 255, 0.4);
}

.play-pause-stop button.pauseBtn {
  border-left: 1px solid rgba(255, 255, 255, 0.4);
  border-right: 1px solid rgba(255, 255, 255, 0.4);
}

.rc-collapse-item .rc-collapse-content {
  padding: 0;
}

.rc-collapse-item .rc-collapse-content .rc-collapse-content-box {
  margin: 0;
}

.rc-collapse > .rc-collapse-item.group_sidebar > .rc-collapse-header {
  background: #003e76;
  color: #fff;
}

.cardimage {
  position: relative;
}

.imagestatus {
  position: absolute;
  top: 3px;
  right: 10px;
}

.fil_btn span.badge {
  margin-left: 8px;
  color: #fff;
  vertical-align: middle;
  height: 19px;
  width: 19px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: static;
}

.fil_btn span.badge button.close {
  color: #fff;
  float: unset;
  font-size: 15px;
  vertical-align: middle;
  font-weight: 300;
  line-height: 1.2;
}

.filter_footer {
  position: fixed;
  bottom: 0;
  padding: 3px 5px 3px 23px;
  z-index: 2;
  border-radius: 0 4px 4px 0;
  border: 1px solid #054988;
  background-color: rgb(239, 245, 250);
}

.fil_btn {
  display: inline-flex;
  align-items: center;
  font-size: 13px;
  padding: 5px 8px;
  margin-right: 10px;
}

input {
  padding: 0.5rem;
  font-size: 16px;
  width: 100%;
  display: block;
  border-radius: 4px;
  border: 1px solid #ccc;
}

input:focus {
  border-color: #007eff;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 0 3px rgba(0, 126, 255, 0.1);
  outline: none;
}

input.error {
  border-color: red;
}

.input-feedback {
  color: red;
  margin-top: 0.15rem;
  font-size: 11px;
}

.filter_popover .popover {
  max-width: 350px;
  width: 100%;
}

.filter_input {
  display: flex;
}

.filter_input input {
  border-radius: 0.25rem 0 0 0.25rem;
}

.filter_input button {
  border-radius: 0 0.25rem 0.25rem 0;
}

.filter_input button .spinner-border {
  position: static;
  margin-right: 5px;
}

.filter_popover h3.popover-header span.badge {
  float: right;
  color: #fff;
  height: 24px;
  width: 24px;
  padding: 0;
  display: flex;
  justify-content: center;
  position: static;
  align-items: flex-start;
}

.filter_popover span.badge button.close {
  color: #fff;
  float: unset;
  font-size: 21px;
  font-weight: 300;
  line-height: 16px;
  outline: none;
}

.filter_popover .popover-body {
  padding-bottom: 20px;
}

.filter_popover .popover {
  box-shadow: 4px 6px 9px 2px rgba(0, 0, 0, 0.4);
}

.checkbox-alignment {
  display: inline-block;
  width: auto;
  margin-top: 2px;
}

.app-wrapper {
  overflow-x: hidden;
}

.side-opened .content-wrapper,
.side-opened .page-details-stripe,
.side-opened .page-details-substripe {
  padding-left: 180px;
}

.nav-item .farmlist {
  padding: 4px 4px 0px 17px;
}

.action-btn {
  margin-left: 5px;
}

.action-btn-group {
  margin-left: 1160px;
  margin-top: -40px;
}

.btn-add-setting {
  margin-right: 20px;
}

.highlight-account {
  background-color: #daf7a6 !important;
}

.date-picker {
  height: 30px;
  z-index: 99999999 !important;
}

.date-picker input {
  height: 30px;
  z-index: 99999999 !important;
}

.status-alignment {
  overflow-y: auto;
  max-height: calc(100vh - 65px);
}

.status-alignment .ReactVirtualized__Grid__innerScrollContainer {
  width: calc(100vw - 196px) !important;
  max-width: calc(100vw - 196px) !important;
}

.side-opened .status-alignment .ReactVirtualized__Grid__innerScrollContainer {
  width: calc(100vw - 376px) !important;
  max-width: calc(100vw - 376px) !important;
}

.spinner-border {
  position: absolute;
  top: calc(50% - 16px);
  left: calc(50% - 16px);
}

.spinner-border-sm {
  top: calc(50% - 8px);
  left: calc(50% - 8px);
}

.ag-react-container {
  z-index: 99999999 !important;
}

.Cell {
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  padding: 0.5rem;
  background-color: #f7f7f7;
  word-break: break-all;
}

.single-line {
  display: flex;
  height: 35px;
  align-items: baseline;
}

.single-line input {
  margin-left: 5px;
}

.single-line-2 {
  display: flex;
  height: 35px;
  align-items: baseline;
  justify-content: space-between;
}

.single-line label {
  margin-right: 20px;
}
.single-line-2 label {
  white-space: nowrap;
  margin-right: 30px;
}

.modal-lg {
  max-width: 90% !important;
}

.script-row:not(:first-child) {
  border-top: 1px solid #d8e6f4;
}

.setting-category {
  width: 100%;
  word-wrap: nowrap;
  background: linear-gradient(90deg, #fff, gainsboro);
}

.script-row-title {
  border-bottom: 1px solid #ccc;
}

.script-row-title span {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: -2px;
  display: inline-block;
  border-bottom: 3px solid #b6d0eb;
  padding: 0px 14px 6px 2px;
}

.script-row-item {
  align-items: baseline;
  margin-bottom: 5px;
}

.script-row-item .positiveValue,
.script-setting-list .positiveValue {
  color: #28a745;
  font-size: 19px;
}
.script-row-item .negativeValue,
.script-setting-list .negativeValue {
  color: #dc3545;
  font-size: 19px;
}

.script-setting-list {
  background-color: #fff;
}

.scriptContentWrapper {
  /* height: calc(100vh - 68px); */
  /* overflow-y: auto; */
  padding: 0 15px;
}

.ag-theme-balham .ag-row-hover {
  background-color: #cfddea !important;
}

.ag-theme-balham .ag-icon-checkbox-checked {
  color: #033b71 !important;
}

.ag-theme-balham .ag-cell-range-selected-1:not(.ag-cell-focus) {
  background-color: rgb(178, 204, 226) !important;
}

.ag-theme-balham
  .ag-ltr
  .ag-has-focus
  .ag-cell-focus:not(.ag-cell-range-selected),
.ag-theme-balham .ag-ltr .ag-has-focus .ag-cell-focus.ag-cell-range-single-cell,
.ag-theme-balham
  .ag-ltr
  .ag-cell-focus.ag-cell-range-single-cell.ag-cell-range-handle,
.ag-theme-balham
  .ag-rtl
  .ag-has-focus
  .ag-cell-focus:not(.ag-cell-range-selected),
.ag-theme-balham .ag-rtl .ag-has-focus .ag-cell-focus.ag-cell-range-single-cell,
.ag-theme-balham
  .ag-rtl
  .ag-cell-focus.ag-cell-range-single-cell.ag-cell-range-handle {
  border: 1px solid #033b70 !important;
}

.ag-theme-balham .ag-fill-handle,
.ag-theme-balham .ag-range-handle {
  background-color: #25598a !important;
}

.ag-theme-balham .ag-popup .context-view-setting {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 75px;
}

.ag-theme-balham .ag-popup .context-view-setting .close-icon {
  cursor: pointer;
}

.global-wrapper #mm_integration {
  width: auto;
  top: -1px;
}

.collapse-filterbar {
  height: 37px;
  padding: 8px 2px;
  width: 20px;
  cursor: pointer;
  position: absolute;
  background-color: #054988;
  color: #fff;
  z-index: 7;
  bottom: 0;
  left: 0;
  border: 1px solid gainsboro;
}

.side-opened .collapse-filterbar {
  left: 179px;
}
.side-opened .filter_footer .collapse-filterbar {
  left: -2px;
}

.config-modal-form {
  max-width: 800px;
}

.config-modal-form .modal-content {
  width: 800px;
}

.config-modal-form .modal-bottom-control {
  position: absolute;
  right: 0;
  bottom: -1px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 15px 15px 0;
}

.config-modal-form .modal-bottom-control button {
  margin-left: 10px;
}

.global-submit-button {
  margin-top: 5px !important;
}

.ag-theme-balham .ag-ltr .ag-column-drop-horizontal {
  padding-left: 66px !important;
}

.sidebar-menu .footer-item {
  position: absolute;
  bottom: 10px;
  width: 100%;
}

.countrySelect {
  border: none;
}

.countrySelect {
  color: grey;
  background-color: transparent;
}

.grid-reset-state-button {
  line-height: 31px;
  text-decoration: underline !important;
  cursor: pointer;
}

.ag-set-filter-item
  .ag-set-filter-item-checkbox.ag-input-field
  .ag-input-field-label.ag-label.ag-checkbox-label.ag-label-ellipsis {
  max-height: 17px;
  line-height: 17px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.config-modal-form2 {
  max-width: 1500px;
}

.config-modal-form2 .modal-content {
  width: 1200px;
}

.config-modal-form2 .json-modal-warning-box {
  color: red;
  font-size: 12px;
  font-weight: 600;
  display: flex;
  align-items: center;
}

.config-modal-form2 .json-modal-warning-box .redo-button {
  color: green;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  margin-left: 7px;
}

.settings-modal-form .custom-switch-right {
  width: 100%;
}
.settings-modal-form .custom-switch-right .custom-control-label {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.rc-collapse {
  background-color: #f7f7f7;
  border-radius: 3px;
  border: 1px solid #d9d9d9;
}
.rc-collapse-anim-active {
  transition: height 0.2s ease-out;
}
.rc-collapse > .rc-collapse-item {
  border-top: 1px solid #d9d9d9;
}
.rc-collapse > .rc-collapse-item:first-child {
  border-top: none;
}
.rc-collapse > .rc-collapse-item > .rc-collapse-header {
  display: flex;
  align-items: center;
  line-height: 22px;
  padding: 4px 8px;
  color: #666;
  cursor: pointer;
}
.rc-collapse > .rc-collapse-item > .rc-collapse-header .arrow {
  display: inline-block;
  content: '\20';
  width: 0;
  height: 0;
  font-size: 0;
  line-height: 0;
  border-top: 3px solid transparent;
  border-bottom: 3px solid transparent;
  border-left: 4px solid #666;
  vertical-align: middle;
  margin-right: 8px;
}
.rc-collapse > .rc-collapse-item > .rc-collapse-header .rc-collapse-extra {
  margin: 0 16px 0 auto;
}
.rc-collapse > .rc-collapse-item-disabled > .rc-collapse-header {
  cursor: not-allowed;
  color: #999;
  background-color: #f3f3f3;
}
.rc-collapse-content {
  overflow: hidden;
  color: #666;
  padding: 0 16px;
  background-color: #fff;
}
.rc-collapse-content > .rc-collapse-content-box {
  margin-top: 16px;
  margin-bottom: 16px;
}
.rc-collapse-content-inactive {
  display: none;
}
.rc-collapse-item:last-child > .rc-collapse-content {
  border-radius: 0 0 3px 3px;
}
.rc-collapse > .rc-collapse-item-active > .rc-collapse-header .arrow {
  position: relative;
  top: 2px;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-top: 4px solid #666;
  margin-right: 6px;
}

